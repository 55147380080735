import { ContainerClient } from '@azure/storage-blob';
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider';
import FileSystemItem from 'devextreme/file_management/file_system_item';

export default class AzureFileSystemProvider extends CustomFileSystemProvider {
    private readonly dir: string;

    constructor(private readonly containerClient: ContainerClient, environment: string) {
        super({
            getItems: async (parentDirectory: FileSystemItem) => {
                return await this.listBlobs(this.getDirectoryPath(this.getFileItemPath(parentDirectory)));
            },
            renameItem: async (item: FileSystemItem, newName: string) => {
                await this.move(item, newName);
            },
            deleteItem(item: FileSystemItem) {
                return containerClient.deleteBlob(item.key);
            }
        });

        this.dir = environment;
    }

    private async move(source: FileSystemItem, newName: string) {
        const dest = source.key.replace("/" + source.name, "/" + newName);
        const sourceBlob = this.containerClient.getBlockBlobClient(source.key);
        const destBlob = this.containerClient.getBlockBlobClient(dest);

        const blob = await sourceBlob.download().then(result => result.blobBody);
        const prop = await sourceBlob.getProperties();

        await destBlob.upload(await blob!.text(), blob!.size, { metadata: { filename: newName, type: prop.metadata!.type } });
        await this.containerClient.deleteBlob(source.key);
    }

    public async upload(parentDirectory: FileSystemItem, name: string, type: string, body: string) {
        const key = this.getDirectoryPath(this.getFileItemPath(parentDirectory)) + name;
        const blobClient = this.containerClient.getBlockBlobClient(key);
        return {
            key: key,
            response: await blobClient.upload(body, body.length, { metadata: { filename: name, type: type } })
        };
    }

    public exists(parentDirectory: FileSystemItem, name: string) {
        const blob = this.containerClient.getBlockBlobClient(this.getDirectoryPath(this.getFileItemPath(parentDirectory)) + name);
        return blob.exists();
    }

    private async listBlobs(prefix: string) {
        const result = [];

        for await (const blob of this.containerClient.listBlobsByHierarchy("/", { prefix: prefix, includeMetadata: true })) {
            if (blob.kind === "blob") {
                result.push({
                    key: blob.name,
                    name: blob.metadata!.filename,
                    type: blob.metadata!.type,
                    dateModified: blob.properties.createdOn,
                    size: blob.properties.contentLength
                });
            }
        }

        return result;
    }

    private getDirectoryPath(path: string) {
        path = path ? this.dir + "/" + path : this.dir;

        if (path.startsWith("/")) {
            path = path.substring(1);
        }

        if (!path.endsWith("/")) {
            // Javascript SDK needs the prefix to end with a slash.
            path += "/";
        }

        return path;
    }

    private getFileItemPath(item: FileSystemItem) {
        return item.path.replace('//', '/');
    }
}