
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { ContainerClient } from '@azure/storage-blob';
    import AzureFileSystemProvider from '../AzureFileSystem';

    import { DxFileManager, DxPermissions } from 'devextreme-vue/file-manager';
    import dxFileManager from 'devextreme/ui/file_manager';
    import { MFile } from '../file';

    @Component({
        components: {
            DxFileManager,
            DxPermissions
        }
    })
    export default class SaveModal extends Vue {
        @Prop() public type?: string;
        @Prop() public code?: string;

        private readonly containerClient = new ContainerClient(this.$sas);
        private readonly fileSystemProvider = new AzureFileSystemProvider(this.containerClient, this.$env);

        private name = "";
        private show = false;

        public $refs: {
            filemanager: any;
        };

        public async save(file?: MFile, type?: string, code?: string) {
            if (file && file.dir && file.name && file.type && type && code) {
                await this.fileSystemProvider.upload(file.dir, file.name, type, code);
                this.$bvModal.msgBoxOk('Your script has been saved.');
                return;
            }

            this.name = "";
            this.show = true;
        }

        private async ok() {
            var dir = (this.$refs.filemanager.instance as dxFileManager).getCurrentDirectory();

            if (!this.name) {
                this.$bvModal.msgBoxOk('A file name is required.');
                return;
            }

            if (this.name.includes("/") || this.name.includes("\\")) {
                this.$bvModal.msgBoxOk('Slashes are not allowed in file names.');
                return;
            }

            if (await this.fileSystemProvider.exists(dir, this.name)) {
                if (!await this.$bvModal.msgBoxConfirm('The file already exists. Do you want to overwrite it?')) {
                    return;
                }
            }

            var result = await this.fileSystemProvider.upload(dir, this.name, this.type!, this.code!);
            if (!result.response.errorCode) {
                this.$emit('file-saved', {
                    key: result.key,
                    dir: dir,
                    name: this.name,
                    type: this.type,
                    body: this.code
                });

                this.show = false;
                this.$bvModal.msgBoxOk('Your script has been saved.');
            }
        }

        private onSelectionChanged(e: any) {
            if (e.selectedItems.length > 0 && !e.selectedItems[0].isDirectory) {
                this.name = e.selectedItems[0].name;
            }
        }
    }
