import { render, staticRenderFns } from "./Save.vue?vue&type=template&id=6fc09406&scoped=true"
import script from "./Save.vue?vue&type=script&lang=ts"
export * from "./Save.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fc09406",
  null
  
)

export default component.exports