
    import { Component, Watch, Vue } from 'vue-property-decorator';

    import * as moment from 'moment';
    import * as monaco from 'monaco-editor';
    import * as ts from 'typescript';

    import SaveModal from './Save.vue';
    import OpenModal from './Open.vue';

    import DxBox, { DxItem as DxBoxItem } from 'devextreme-vue/box';
    import DxToolbar, { DxItem as DxToolbarItem } from 'devextreme-vue/toolbar';
    import DxButton from 'devextreme-vue/button';
    import DxList from 'devextreme-vue/list';
    import DxTextArea from 'devextreme-vue/text-area';

    import { dxToolbarItem } from 'devextreme/ui/toolbar';

    // Assets.
    import libES5 from '@/assets/lib.es5.txt';
    import es2015Promise from '@/assets/lib.es2015.promise.txt';
    import es2017String from '@/assets/lib.es2017.string.txt';
    import definitions from '@/assets/definitions.d.txt';
    import m from '@/assets/moment.d.txt';

    import helpers from '../helpers';
    import { MFile, ExportResult } from '../file';

    @Component({
        components: {
            SaveModal,
            OpenModal,

            DxBox,
            DxBoxItem,
            DxToolbar,
            DxButton,
            DxList,
            DxTextArea
        }
    })
    export default class Home extends Vue {
        private editor: monaco.editor.IStandaloneCodeEditor;

        public mounted() {
            this.editor = monaco.editor.create(this.$refs.editor, {
                language: "typescript",
                value: this.code,
                minimap: {
                    enabled: false
                },
                automaticLayout: true,
                scrollBeyondLastLine: false
            });

            this.editor.onDidChangeModelContent(e => {
                const value = this.editor.getValue()
                if (this.code !== value) {
                    this.code = value;
                }
            });

            var typescript = monaco.languages.typescript;
            typescript.typescriptDefaults.setCompilerOptions({
                target: typescript.ScriptTarget.ES2018,
                allowNonTsExtensions: true,
                noLib: true
            });

            typescript.typescriptDefaults.addExtraLib(libES5);
            typescript.typescriptDefaults.addExtraLib(es2015Promise);
            typescript.typescriptDefaults.addExtraLib(es2017String);
            typescript.typescriptDefaults.addExtraLib(definitions);
            typescript.typescriptDefaults.addExtraLib(m);
        }

        public $refs: {
            save_modal: any;
            open_modal: any;
            editor: HTMLElement;
        };

        public code = `async function process(data: GL): Promise<ExportResult> {
    // Your code here...
}`;

        public errors = new Array < { text: string; } > ();
        public output = "";
        public type = "GLString";
        public data = JSON.stringify(GLString, null, 2);

        public file?: MFile = {
            key: "",
            name: ""
        };

        public library = [
            {
                text: "toText(output: string)",
                description: "Takes in a string and outputs a plain text result."
            },
            {
                text: "toCSV(output: any[])",
                description: "Takes in an array of data and outputs a delimited result. Header visibility can be customized."
            },
            {
                text: "toXLSX(output: any[])",
                description: "Takes in an array of data and outputs a Excel file result. Header visibility can be customized."
            }
        ];

        public templates = [
            {
                heading: "toText",
                description: `
Manually create a csv file by specifying your own commas and header row.
Contains code to split up the GL account number into an array based on a pipe and dash character, format the period end date and name the file accordingly.`,
                code: `
async function process(data: GL): Promise<ExportResult> {
    var glHeader = "";
    var glString = "";

    // The \\n at the end of the line is the equivalent of an enter key
    glHeader = "Branch No, Date, Account, Debit-Credit, Ledger \\n";

    // Loop through all records

    data.GeneralLedgerLines.forEach(line => {
        var glFullAccNo = line.GeneralLedgerAccountNumber.split("|");
        var glSegmentAccNo = glFullAccNo[0].split("-");

        glString = glString
            + glSegmentAccNo[3] + ", "
            + moment(line.PeriodEndDate).format('DD-MM-yyyy') + ", "
            + glFullAccNo[0] + ", D, G \\n";
    });

    // Append the header and details rows together
    var fileContents = glHeader + glString;

    var fileName = "CustomNameGoesHere " + moment(data.GeneralLedgerLines[0].PeriodEndDate).format('DD-MM-yyyy') + ".csv";
    return toText(fileContents, { filename: fileName });
}
`
            },
            {
                heading: "toText",
                description: `
Create a tab delimited file.
Assumes the GL String is setup with pipe characters to separate the various segments to be used in the file.
Includes a function to append zeros to fields to be a fixed length.`,
                code: `
async function process(data: GL): Promise<ExportResult> {
    var AppendZerosLeft = (value: string, zeroCount: number) => {
        while (value?.length < zeroCount) {
            value = "0" + value;
        }

        return value;
    };

    var glHeader = "";
    var glString = "";

    // The \\t represent a tab character i.e. you hit the tab key on your keyboard
    // The \\n at the end of the line is the equivalent of an enter key
    glHeader = "Branch No\\tDate\\tAccount\\tDebit-Credit\\tLedger\\n";

    // Loop through all records

    data.GeneralLedgerLines.forEach(line => {
        var glFullAccNo = line.GeneralLedgerAccountNumber.split("|");
        glString = glString
            + AppendZerosLeft(glFullAccNo[1], 10) + "\\t"
            + moment(line.PeriodEndDate).format('DD-MM-yyyy') + "\\t"
            + glFullAccNo[0] + " \\t D \\t G \\n";
    });

    // Append the header and details rows together
    var fileContents = glHeader + glString;

    var fileName = "CustomNameGoesHere " + moment(data.GeneralLedgerLines[0].PeriodEndDate).format('DD-MM-yyyy') + ".txt";
    return toText(fileContents, { filename: fileName });
}
`
            },
            {
                heading: "toCSV",
                description: `
Simple loop with an optional attribute to show column headers.
Includes logic to display amount as negative value if it's a credit record.
Remember to select the "GL String and Component" grouping on the Parameters tab in order to reference the component description.`,
                code: `
async function process(data: GL): Promise<ExportResult> {
    var glFile = [];

    // Loop through all records

    data.GeneralLedgerLines.forEach(line => {
        // Check if the record is a credit

        var glAmount = 0;
        if (line.DebitOrCredit === "CR") {
            glAmount = line.Amount * -1;
        }
        else {
            glAmount = line.Amount;
        }

        glFile.push({
            "GL Number": line.GeneralLedgerAccountNumber,
            "Component Name": line.Description,
            "Amount": glAmount
        });
    });

    return toCSV(glFile, { filename: "MyOwnFileName.csv", showHeaders: true });
}`
            },
            {
                heading: "toXLSX",
                description: `
Simple loop with an optional attribute to show column headers.
Includes logic to display amount as negative value if it's a credit record.
Remember to select the "GL String and Component" grouping on the Parameters tab in order to reference the component description.`,
                code: `
async function process(data: GL): Promise<ExportResult> {
    var glFile = [];

    // Loop through all records

    data.GeneralLedgerLines.forEach(line => {
        // Check if the record is a credit

        var glAmount = 0;
        if (line.DebitOrCredit == "CR") {
            glAmount = line.Amount * -1;
        }
        else {
            glAmount = line.Amount;
        }

        glFile.push({
            "GL Number": line.GeneralLedgerAccountNumber,
            "Component Name": line.Description,
            "Amount": glAmount
        });
    });

    return toXLSX(glFile, { filename: "MyOwnFileName.xlsx", showHeaders: true });
}`
            }];

        public toolbarOptions: dxToolbarItem[] = [{
            location: "before",
            locateInMenu: "auto",
            widget: "dxButton",
            options: {
                text: 'Validate',
                icon: 'codeblock',
                onClick: () => {
                    this.validate();
                }
            }
        },
        {
            location: "before",
            locateInMenu: "auto",
            widget: "dxButton",
            options: {
                text: 'Test',
                icon: 'runner',
                onClick: () => {
                    this.test();
                }
            }
        },
        {
            location: "before",
            locateInMenu: "auto",
            widget: "dxButton",
            options: {
                text: 'Download',
                icon: 'download',
                onClick: () => {
                    this.download();
                }
            },
        },
        {
            location: "after",
            locateInMenu: "auto",
            widget: "dxButton",
            options: {
                text: 'Save',
                icon: 'save',
                onClick: () => {
                    this.save();
                }
            },
        },
        {
            location: "after",
            locateInMenu: "auto",
            widget: "dxButton",
            options: {
                text: 'Save as',
                icon: 'save',
                onClick: () => {
                    this.saveAs();
                }
            },
        },
        {
            location: "after",
            locateInMenu: "auto",
            widget: "dxButton",
            options: {
                text: 'Open',
                icon: 'activefolder',
                onClick: () => {
                    this.open();
                }
            }
        }];

        public validate() {
            var markers = monaco.editor.getModelMarkers({});
            if (markers) {
                this.errors = markers.map(marker => ({ text: marker.message }));
            }
        }

        public async test() {
            this.errors = [];

            try {
                var result = await this.execute(this.code, this.data);
                this.output = result.output;
            } catch (e: any) {
                if ("message" in e) {
                    this.errors = [{ text: e.message }];
                }
            }
        }

        public async download() {
            this.errors = [];

            try {
                var result = await this.execute(this.code, this.data);
                switch (result.type) {
                    case "text":
                        helpers.downloadToFile(result.output, "output.txt", "text/plain");
                        break;
                    case "csv":
                        helpers.downloadToFile(result.output, "output.csv", "text/csv");
                        break;
                }
            } catch (e: any) {
                if ("message" in e) {
                    this.errors = [{ text: e.message }];
                }
            }
        }

        public save() {
            this.$refs.save_modal.save(this.file, this.type, this.code);
        }

        public saveAs() {
            this.$refs.save_modal.save();
        }

        public open() {
            this.$refs.open_modal.open();
        }

        @Watch('code')
        private onCodeChanged(newValue: string, oldValue: string) {
            if (newValue !== this.editor.getValue()) {
                this.editor.setValue(newValue)
            }
        }

        @Watch('type')
        private onTypeChanged(newValue: string, oldValue: string) {
            this.data = JSON.stringify((window as any)[newValue], null, 2);
        }

        private onLibraryItemRendered(e: { itemElement: HTMLElement; itemData: { text: string; } }) {
            e.itemElement.ondblclick = () => {
                this.editor?.trigger('keyboard', 'type', { text: e.itemData.text });
            };
        }

        private onTemplateItemRendered(e: { itemElement: HTMLElement; itemData: { code: string; } }) {
            e.itemElement.ondblclick = () => {
                this.code = e.itemData.code.trim();
            };
        }

        private onFileOpened(file: MFile) {
            this.file = file;
            this.type = file.type!;
            this.code = file.body!;
        }

        private async execute(code: string, data: string): Promise<ExportResult> {
            var js = ts.transpile(code, { target: ts.ScriptTarget.ESNext });
            return await eval("(function(){return " + js + "})()(" + data + ")");
        }
    }
