
    import { Component, Vue } from 'vue-property-decorator';
    import { ContainerClient } from '@azure/storage-blob';
    import AzureFileSystemProvider from '../AzureFileSystem';

    import { DxFileManager, DxPermissions } from 'devextreme-vue/file-manager';
    import dxFileManager from 'devextreme/ui/file_manager';

    @Component({
        components: {
            DxFileManager,
            DxPermissions
        }
    })
    export default class OpenModal extends Vue {
        private readonly containerClient = new ContainerClient(this.$sas);
        private readonly fileSystemProvider = new AzureFileSystemProvider(this.containerClient, this.$env);

        private show = false;

        public $refs: {
            filemanager: any;
        };

        public open() {
            this.show = true;
        }

        private async ok() {
            var filemanager = this.$refs.filemanager.instance as dxFileManager;

            var items = filemanager.getSelectedItems();
            if (items.length === 0) {
                return;
            }

            var item = items[0];
            const blobClient = this.containerClient.getBlockBlobClient(item.dataItem.key);

            var file = await blobClient.download();
            var body = await file.blobBody;
            if (body) {
                this.$emit('file-selected', {
                    key: item.key,
                    dir: filemanager.getCurrentDirectory(),
                    name: item.name,
                    type: item.dataItem.type,
                    body: await this.blobToString(body)
                });
            }

            this.show = false;
        }

        private async blobToString(blob: Blob): Promise<string> {
            return new Promise((resolve, reject) => {
                var fileReader = new FileReader();

                fileReader.onerror = () => {
                    reject(fileReader.error);
                };
                fileReader.onload = () => {
                    resolve(fileReader.result as string);
                };

                fileReader.readAsText(blob);
            });
        }
    }
