export default {
    downloadToFile(content: string, fileName: string, mimeType?: string) {
        const a = document.createElement('a');
        mimeType = mimeType || 'application/octet-stream';

        if (URL && 'download' in a) { //html5 A[download]
            a.href = URL.createObjectURL(new Blob([content], {
                type: mimeType
            }));
            a.setAttribute('download', fileName);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } else {
            location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
        }
    }
}