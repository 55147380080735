import Vue from 'vue';
import App from './App.vue';
import { BootstrapVue, BootstrapVueIcons  } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'devextreme/dist/css/dx.light.css';

const params = new URLSearchParams(window.location.search);
const env = params.get('env');
const sas = params.get('sas');

if (env && sas) {
    Vue.prototype.$env = env
    Vue.prototype.$sas = sas

    Vue.use(BootstrapVue)
    Vue.use(BootstrapVueIcons)

    new Vue({
        render: h => h(App)
    }).$mount('#app');
} else {
    document.getElementById("app")!.innerText = "401 Unauthorised";
}